<template>
    <div>
        <v-form v-model="valid">
            <v-row no-gutters align="center" class="mt-10">
                <v-col v-if="!$route.params.sid" cols="3">
                    <v-select
                        required
                        clearable
                        :items="spaceNames"
                        dense
                        label="Space"
                        class="hide-selector-extra-input mr-6"
                        v-model="selectedSpace"
                    ></v-select>
                </v-col>
                <v-col v-if="metric === metrics.HPC_USAGE" cols="3">
                    <v-select required clearable :items="userNames" dense label="User" class="hide-selector-extra-input mr-6" v-model="selectedUser"></v-select>
                </v-col>
                <v-col cols="3">
                    <div class="d-flex flex-column justify-end">
                        <v-select
                            required
                            :items="dateRangeOptions"
                            dense
                            label="Date range"
                            class="hide-selector-extra-input"
                            v-model="selectedDateRangeOption"
                            @change="updateDateRange()"
                        ></v-select>
                        <v-menu
                            v-if="selectedDateRangeOption === 'Custom range'"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    :rules="[rules.rangeRequired, rules.maxRange, rules.dateOrder]"
                                    dense
                                    required
                                    v-model="dateRangeText"
                                    label="Range"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker :allowed-dates="allowedDates" v-model="dataDateRange" range></v-date-picker>
                        </v-menu>
                    </div>
                </v-col>
                <v-col :cols="$route.params.sid && metric === metrics.HPC_USAGE ? 6 : $route.params.sid ? 9 : metric === metrics.HPC_USAGE ? 3 : 6">
                    <div class="d-flex justify-start">
                        <v-btn icon :disabled="!valid" :loading="fetchingData" @click="fetchStatistic" text class="ml-5" color="primary"
                            ><v-icon>refresh</v-icon></v-btn
                        >
                    </div>
                </v-col>
            </v-row>
        </v-form>
        <div v-if="!fetchingData && chartData && chartData.length && completeSummaryData && completeSummaryData.length">
            <div class="d-flex justify-center mt-5">
                <span class="subtitle-2 primary--text">Reported data is as of {{ new Date().toISOString().slice(0, 10) }} 02:00 AM CEST</span>
            </div>
            <v-divider></v-divider>
            <div>
                <v-data-iterator
                    :page="page"
                    v-if="completeSummaryData && completeSummaryData.length"
                    :items="completeSummaryData"
                    :items-per-page.sync="itemsPerPage"
                    hide-default-footer
                >
                    <template v-slot:default="props">
                        <v-row class="py-5 px-5" align="center" justify="space-around">
                            <v-col v-for="(data, index) in props.items" :key="index" :cols="numberRowColumnsDoughnutCharts">
                                <div class="d-flex flex-column align-center">
                                    <div class="d-flex align-center">
                                        <span class="subtitle-2 primary--text">{{ data.title }}</span>
                                        <v-menu :close-on-content-click="false" open-on-hover :nudge-width="200" offset-x>
                                            <template v-slot:activator="{ on }">
                                                <v-btn class="ml-1" small v-on="on" icon>
                                                    <v-icon small>help_outline</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-card max-width="300" flat>
                                                <v-card-text>
                                                    <span
                                                        >Please refer to our
                                                        <a
                                                            href="https://docs.nuvolos.cloud/settings-and-administration/monitoring-resource-usage"
                                                            target="_blank"
                                                            >documentation</a
                                                        >
                                                        for precise definitions and interpretation of the metrics provided in this report.</span
                                                    >
                                                </v-card-text>
                                            </v-card>
                                        </v-menu>
                                    </div>
                                    <v-avatar v-if="false" class="mt-3" color="secondary" size="100">
                                        <span class="white--text display-1">{{ data.value }}</span>
                                    </v-avatar>
                                    <div class="max-width:300px;max-height:300px">
                                        <doughnutChart :chartData="data.distribution" :aggregateValue="data.value" :chartIndex="`${index}`"></doughnutChart>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-if="numberOfDoughnutChartPages > 1" v-slot:footer>
                        <div class="d-flex justify-end align-center">
                            <span class="mr-4"> Page {{ page }} of {{ numberOfDoughnutChartPages }} </span>
                            <v-btn :disabled="page === 1" small color="info" class="mr-1" @click="formerPage">
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>
                            <v-btn :disabled="page === numberOfDoughnutChartPages" small color="info" class="ml-1" @click="nextPage">
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-data-iterator>
            </div>

            <v-divider class="mt-5" v-if="chartData.length" />

            <div v-if="chartData !== undefined && chartData.length && !fetchingData" class="d-flex justify-space-between align-center mt-10 mb-5">
                <v-btn-toggle v-model="tableChartToggle">
                    <v-btn small max-height="40" min-width="50%">Chart</v-btn>
                    <v-btn small max-height="40" min-width="50%">Table</v-btn>
                </v-btn-toggle>
                <v-select
                    v-if="tableChartToggle === dataDisplayModes.CHART"
                    outlined
                    :items="options"
                    v-model="selectedVariable"
                    class="mr-3"
                    hide-details
                    dense
                    label="Quantity"
                    style="max-width:450px"
                >
                </v-select>
                <v-btn v-else @click="exportCSVFile(csvHeaders, tableChartData, 'metrics')" class="primary--text" text
                    ><v-icon>mdi-download</v-icon>download as csv</v-btn
                >
            </div>
            <div v-if="chartData !== undefined && chartData.length">
                <div v-show="chartData && selectedVariable && tableChartToggle === dataDisplayModes.CHART">
                    <barChart
                        v-if="chartData"
                        :maxNumberYTicks="distinctDataCount"
                        :chartData="barChartData"
                        :chartYaxisLabel="barchartYAxisLabelStrings[this.$data.selectedVariable[0]]"
                    ></barChart>
                </div>
                <div v-show="tableChartToggle === dataDisplayModes.TABLE">
                    <v-data-table :headers="tableHeaders" :items="tableChartData"></v-data-table>
                </div>
            </div>
        </div>
        <div v-else-if="fetchingData" class="d-flex flex-column">
            <v-row justify="space-around" no-gutters>
                <v-col v-for="(n, i) in 3" :key="i" cols="3">
                    <v-skeleton-loader class="mx-auto" type="list-item-two-line"></v-skeleton-loader>
                </v-col>
            </v-row>
            <v-row justify="space-around" no-gutters>
                <v-col v-for="(n, i) in 3" :key="i" cols="3">
                    <v-skeleton-loader type="image" class="py-10" />
                </v-col>
            </v-row>
            <div>
                <v-skeleton-loader class="mx-auto" type="list-item-two-line"></v-skeleton-loader>
            </div>
            <div>
                <v-skeleton-loader type="image" class="py-10" />
            </div>
        </div>
        <div class="mt-10" v-else-if="fetchingData === false">
            <v-banner single-line>
                <v-avatar slot="icon" color="white" size="60">
                    <v-icon x-large icon="info" color="info">
                        info
                    </v-icon>
                </v-avatar>
                <div class="d-flex flex-column">
                    <span class="font-weight-bold">No data found</span>
                    No data were found for the selected date range.
                </div>
            </v-banner>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { calculateDateDifferenceInDays, roundNumber, getDateRangeArray } from '@/utils'
const barChart = () => import('@/components/BarChart.vue')
const doughnutChart = () => import('@/components/DoughnutChart.vue')

export default {
    name: 'ReportingDashboardData',
    components: { barChart, doughnutChart },
    props: {
        metric: String,
        spaceNames: Array,
        userNames: Array,
        dialogStatus: Boolean
    },
    data() {
        return {
            selectedUser: 'All users',
            selectedDateRangeOption: 'Last thirty days',
            selectedSpace: '',
            metricVariables: [
                'Activity',
                'Maximum concurrent',
                'Runtime',
                'CPU hour',
                'GB RAM hour',
                'Submitted',
                'Number of users',
                'Faculty',
                'Member',
                'Manager',
                'End-user',
                'Space administrator',
                'Accepted',
                'Pending',
                'Total',
                'Organization users',
                'Space users'
            ],
            barchartYAxisLabelStrings: {
                Activity: 'count',
                'Maximum concurrent': 'count',
                Runtime: 'hours',
                'CPU hour': 'cpu hours',
                'GB RAM hour': 'gb ram hours',
                Submitted: 'count',
                'Number of users': 'count',
                Faculty: 'count',
                Member: 'count',
                Manager: 'count',
                'End-user': 'count',
                'Space administrator': 'count',
                Accepted: 'count',
                Pending: 'count',
                Total: 'count',
                'Organization users': 'count',
                'Space users': 'count'
            },
            defaultVariables: ['Maximum concurrent', 'End-user', 'Pending', 'Member', 'CPU hour'],
            metrics: {
                APP_USAGE: 'apps',
                HPC_USAGE: 'hpc',
                USERS_STATISTICS: 'users',
                INVITATION_STATISTICS: 'invitations'
            },
            tableChartToggle: 0,
            dataDisplayModes: {
                CHART: 0,
                TABLE: 1
            },
            itemsPerPage: 3,
            page: 1,
            dateRanges: [],
            fetchingData: false,
            selectedVariable: null,
            valid: false,
            chartData: [],
            summaryData: null,
            distributionData: [],
            dataDateRange: [],
            tableHeaders: [],
            csvHeaders: [],
            options: [],
            dateRangeOptionsRanges: [],
            dateRangeOptions: ['Last thirty days', 'Current month', 'Previous month', 'Last week', 'Last three days', 'Custom range'],
            datePickerAllowedRange: [],
            doughnutChartColors: ['#17A7C1', '#1799c1', '#178ec1', '#177dc1', '#176cc1', '#0464c4', '#0358ad', '#023e7a', '#022c57'],
            rules: {
                rangeRequired: value => value.split('to').length === 2 || 'Select start and end date',
                maxRange: value =>
                    (value.split('to').length === 2 && calculateDateDifferenceInDays(value.split('to')[0], value.split('to')[1]) <= 30) ||
                    'Range cannot exceed 30 days',
                dateOrder: value => (value.split('to').length === 2 && this.areDatesInOrder(value) === true) || 'Start date should be smaller than end date'
            }
        }
    },
    computed: {
        ...mapState(['userOrgs']),
        ...mapState('orgStore', ['spacesWithPrimarySnapshots', 'orgUsers']),
        ...mapState('spaceStore', ['spaceUsers']),
        ...mapGetters('orgStore', ['isOrgManager']),
        screenSize() {
            if (this.$vuetify.breakpoint.smAndDown) {
                return 'small'
            } else if (this.$vuetify.breakpoint.mdAndDown) {
                return 'medium'
            } else if (this.$vuetify.breakpoint.lgAndDown) {
                return 'large'
            } else if (this.$vuetify.breakpoint.lgAndUp) {
                return 'xlarge'
            }
            return 'large'
        },
        numberRowColumnsDoughnutCharts() {
            if (this.screenSize === 'small' || (this.completeSummaryData && this.completeSummaryData.length === 1)) {
                return 12
            } else if (this.screenSize === 'medium') {
                return 6
            }
            return 3
        },
        numberOfDoughnutChartPages() {
            if (this.completeSummaryData) {
                return Math.ceil(this.completeSummaryData.length / this.itemsPerPage)
            }
            return 0
        },
        dateRangeText() {
            if (this.dataDateRange) {
                return this.dataDateRange.join(' to ')
            }
            return ''
        },
        distinctDataCount() {
            if (this.$data.selectedVariable && this.$data.chartData) {
                const data = this.$data.chartData.map(item => item[this.$data.selectedVariable])
                const uniqueDataPoints = new Set(data)
                return uniqueDataPoints.size
            }
            return 2
        },
        tableChartData() {
            if (this.chartData) {
                const displayedVariables = Object.keys(this.chartData[0]).filter(header => header !== 'Reporting date')
                const truncatedData = this.chartData.map(item => {
                    const filtered = Object.keys(item)
                        .filter(key => displayedVariables.includes(key))
                        .reduce((obj, key) => {
                            if (parseFloat(item[key]) && key !== 'Data date') {
                                obj[key] = roundNumber(item[key], 2)
                            } else {
                                obj[key] = item[key]
                            }
                            return obj
                        }, {})
                    return filtered
                })
                return truncatedData
            }
            return []
        },
        barChartData() {
            if (this.$data.selectedVariable && this.$data.chartData) {
                const data = this.$data.chartData.map(item => item[this.$data.selectedVariable])
                const roundedData = data.map(item => roundNumber(item, 2))
                const labels = this.$data.chartData.map(item => item['Data date'])
                const fullBarChartData = {
                    labels: labels,
                    datasets: [
                        {
                            backgroundColor: '#17A7C1',
                            pointBackgroundColor: 'white',
                            borderWidth: 1,
                            pointBorderColor: '#249EBF',
                            data: roundedData
                        }
                    ]
                }
                return fullBarChartData
            }
            return {}
        },
        completeSummaryData() {
            if (this.summaryData && this.distributionData) {
                let data
                if (this.$props.metric === this.metrics.USERS_STATISTICS) {
                    data = [this.summaryData.Field]
                } else {
                    data = Object.keys(this.summaryData)
                }
                const filteredData = data.filter(item => this.$data.metricVariables.includes(item))
                const fullData = filteredData.map(item => {
                    let aggragatedValue
                    let itemDistributionData
                    let itemLabels
                    let itemValues
                    if (this.$props.metric === this.metrics.USERS_STATISTICS) {
                        itemLabels = this.$data.distributionData.map(data => data.Field)
                        itemValues = this.$data.distributionData.map(data => roundNumber(data.Value, 1))
                        aggragatedValue = this.summaryData.Value
                    } else {
                        itemDistributionData = this.$data.distributionData.filter(data => data.Field === item)
                        itemLabels =
                            this.selectedSpace === 'All spaces'
                                ? itemDistributionData.map(data => data.Space)
                                : this.$props.metric === this.metrics.HPC_USAGE
                                ? itemDistributionData.map(data => data.Username)
                                : itemDistributionData.map(data => data.Instance)
                        itemValues = itemDistributionData.map(data => roundNumber(data.Value, 1))
                        aggragatedValue = this.summaryData[item]
                    }
                    const itemColors = this.$data.doughnutChartColors.slice(0, itemValues.length)
                    const chartDistributionData = {
                        labels: itemLabels,
                        datasets: [
                            {
                                backgroundColor: itemColors,
                                data: itemValues
                            }
                        ]
                    }
                    return {
                        title: item,
                        value: roundNumber(aggragatedValue, 0).toLocaleString('en-US'),
                        distribution: chartDistributionData
                    }
                })
                return fullData
            }
            return []
        }
    },
    methods: {
        updateDateRange() {
            this.$data.dataDateRange = this.$data.dateRangeOptionsRanges[this.$data.selectedDateRangeOption]
        },
        allowedDates(val) {
            if (new Date(val) > new Date() || (this.datePickerAllowedRange && this.datePickerAllowedRange.indexOf(val) !== -1)) {
                return true
            } else {
                return false
            }
        },
        nextPage() {
            if (this.page + 1 <= this.numberOfDoughnutChartPages) this.page += 1
        },
        formerPage() {
            if (this.page - 1 >= 1) this.page -= 1
        },
        setInitialDoughnutNumberPerPage() {
            if (this.screenSize === 'large' || this.screenSize === 'medium') {
                this.$data.itemsPerPage = 2
            } else if (this.screenSize === 'small') {
                this.$data.itemsPerPage = 1
            } else {
                this.$data.itemsPerPage = 3
            }
        },
        setDateRanges() {
            const dateRangeOptions = {}
            var end = new Date()
            end.setDate(end.getDate() - 1)
            var endString = end.toISOString().slice(0, 10)
            if (end.getDate() > 1) {
                const currentMonth = new Date(Date.UTC(end.getFullYear(), end.getMonth(), 1))
                dateRangeOptions['Current month'] = [currentMonth.toISOString().slice(0, 10), endString]
            }
            var previousMonth = new Date()
            previousMonth.setMonth(previousMonth.getMonth() - 1)
            var previousMonthStart = new Date(previousMonth.getFullYear(), previousMonth.getMonth(), 2)
            var previousMonthEnd = new Date(previousMonth.getFullYear(), previousMonth.getMonth() + 1, 1)
            var previousMonthStartString = previousMonthStart.toISOString().slice(0, 10)
            var previousMonthEndString = previousMonthEnd.toISOString().slice(0, 10)
            dateRangeOptions['Previous month'] = [previousMonthStartString, previousMonthEndString]

            var lastThirtyDays = new Date()
            lastThirtyDays.setDate(lastThirtyDays.getDate() - 31)
            dateRangeOptions['Last thirty days'] = [lastThirtyDays.toISOString().slice(0, 10), endString]
            dateRangeOptions['Custom range'] = [lastThirtyDays.toISOString().slice(0, 10), endString]
            var lastWeek = new Date()
            lastWeek.setDate(lastWeek.getDate() - 8)
            dateRangeOptions['Last week'] = [lastWeek.toISOString().slice(0, 10), endString]

            var lastThreeDays = new Date()
            lastThreeDays.setDate(lastThreeDays.getDate() - 4)
            dateRangeOptions['Last three days'] = [lastThreeDays.toISOString().slice(0, 10), endString]
            this.$data.dataDateRange = dateRangeOptions['Last thirty days']
            this.$data.dateRangeOptionsRanges = dateRangeOptions
        },
        areDatesInOrder(datesString) {
            const firstDate = new Date(datesString.split('to')[0])
            const secondDate = new Date(datesString.split('to')[1])
            if (firstDate > secondDate) {
                return false
            } else {
                return true
            }
        },
        getReportApiUrl() {
            var apiBaseURL = `/reporting/org/${this.$route.params.oid}`
            var apiBaseSpaceURL = `${apiBaseURL}/space/${this.$route.params.sid}`
            var apiURL
            if (!this.$route.params.sid && this.$props.metric === this.metrics.APP_USAGE) {
                apiURL = `${apiBaseURL}/app_usage`
            } else if (!this.$route.params.sid && this.$props.metric === this.metrics.INVITATION_STATISTICS) {
                apiURL = `${apiBaseURL}/invitation_status`
            } else if (!this.$route.params.sid && this.$props.metric === this.metrics.USERS_STATISTICS) {
                apiURL = `${apiBaseURL}/user_report`
            } else if (!this.$route.params.sid && this.$props.metric === this.metrics.HPC_USAGE) {
                apiURL = `${apiBaseURL}/hpc_usage`
            } else if (this.$route.params.sid && this.$props.metric === this.metrics.APP_USAGE) {
                apiURL = `${apiBaseSpaceURL}/app_usage`
            } else if (this.$route.params.sid && this.$props.metric === this.metrics.INVITATION_STATISTICS) {
                apiURL = `${apiBaseSpaceURL}/invitation_status`
            } else if (this.$route.params.sid && this.$props.metric === this.metrics.USERS_STATISTICS) {
                apiURL = `${apiBaseSpaceURL}/user_report`
            } else if (this.$route.params.sid && this.$props.metric === this.metrics.HPC_USAGE) {
                apiURL = `${apiBaseSpaceURL}/hpc_usage`
            }
            return apiURL
        },
        getSummaryApiUrl() {
            var apiBaseURL = `/reporting/org/${this.$route.params.oid}`
            var apiBaseSpaceURL = `${apiBaseURL}/space/${this.$route.params.sid}`
            var apiURL
            if (!this.$route.params.sid && this.$props.metric === this.metrics.APP_USAGE) {
                apiURL = `${apiBaseURL}/app_usage_summary`
            } else if (!this.$route.params.sid && this.$props.metric === this.metrics.INVITATION_STATISTICS) {
                apiURL = `${apiBaseURL}/invitation_status_summary`
            } else if (!this.$route.params.sid && this.$props.metric === this.metrics.USERS_STATISTICS) {
                apiURL = `${apiBaseURL}/user_report_summary`
            } else if (!this.$route.params.sid && this.$props.metric === this.metrics.HPC_USAGE) {
                apiURL = `${apiBaseURL}/hpc_usage_summary`
            } else if (this.$route.params.sid && this.$props.metric === this.metrics.APP_USAGE) {
                apiURL = `${apiBaseSpaceURL}/app_usage_summary`
            } else if (this.$route.params.sid && this.$props.metric === this.metrics.INVITATION_STATISTICS) {
                apiURL = `${apiBaseSpaceURL}/invitation_status_summary`
            } else if (this.$route.params.sid && this.$props.metric === this.metrics.USERS_STATISTICS) {
                apiURL = `${apiBaseSpaceURL}/user_report_summary`
            } else if (this.$route.params.sid && this.$props.metric === this.metrics.HPC_USAGE) {
                apiURL = `${apiBaseSpaceURL}/hpc_usage_summary`
            }
            return apiURL
        },
        getSummaryDistributionApiUrl() {
            var apiBaseURL = `/reporting/org/${this.$route.params.oid}`
            var apiBaseSpaceURL = `${apiBaseURL}/space/${this.$route.params.sid}`
            var apiURL
            if (!this.$route.params.sid && this.$props.metric === this.metrics.APP_USAGE) {
                apiURL = `${apiBaseURL}/app_usage_distribution`
            } else if (!this.$route.params.sid && this.$props.metric === this.metrics.INVITATION_STATISTICS) {
                apiURL = `${apiBaseURL}/invitation_status_distribution`
            } else if (!this.$route.params.sid && this.$props.metric === this.metrics.USERS_STATISTICS) {
                apiURL = `${apiBaseURL}/user_report_distribution`
            } else if (!this.$route.params.sid && this.$props.metric === this.metrics.HPC_USAGE) {
                apiURL = `${apiBaseURL}/hpc_usage_distribution`
            } else if (this.$route.params.sid && this.$props.metric === this.metrics.APP_USAGE) {
                apiURL = `${apiBaseSpaceURL}/app_usage_distribution`
            } else if (this.$route.params.sid && this.$props.metric === this.metrics.INVITATION_STATISTICS) {
                apiURL = `${apiBaseSpaceURL}/invitation_status_distribution`
            } else if (this.$route.params.sid && this.$props.metric === this.metrics.USERS_STATISTICS) {
                apiURL = `${apiBaseSpaceURL}/user_report_distribution`
            } else if (this.$route.params.sid && this.$props.metric === this.metrics.HPC_USAGE) {
                apiURL = `${apiBaseSpaceURL}/hpc_usage_distribution`
            }
            return apiURL
        },
        getPostBodyForReporting() {
            const today = new Date()
            today.setDate(today.getDate() - 1)
            const yesterdayISO = today.toISOString()
            var postBody = {
                start_date: this.$data.dataDateRange[0],
                end_date: this.$data.dataDateRange[1],
                oid: this.$route.params.oid,
                reporting_date: yesterdayISO
            }
            if (this.$route.params.sid) {
                postBody.sid = this.$route.params.sid
            } else if (!this.$route.params.sid && this.selectedSpace !== 'All spaces') {
                const selectedSpaceData = this.spacesWithPrimarySnapshots.find(space => space.space_long_id === this.selectedSpace)
                postBody.sid = selectedSpaceData.sid
            }
            if (this.$route.params.sid && this.$props.metric === this.metrics.HPC_USAGE && this.selectedUser !== 'All users') {
                const selectedUserData = this.spaceUsers.find(user => user.full_name === this.selectedUser)
                postBody.uid = selectedUserData.uid
            } else if (this.$route.params.oid && this.$props.metric === this.metrics.HPC_USAGE && this.selectedUser !== 'All users') {
                const selectedUserData = this.orgUsers.find(user => user.full_name === this.selectedUser)
                postBody.uid = selectedUserData.uid
            }
            return postBody
        },
        getPostBodyForSummary() {
            const today = new Date()
            today.setDate(today.getDate() - 1)
            const yesterdayISO = today.toISOString()
            var postBody = {
                start_date: this.$data.dataDateRange[0],
                end_date: this.$data.dataDateRange[1],
                reporting_date: yesterdayISO
            }
            if (!this.$route.params.sid && this.selectedSpace !== 'All spaces') {
                const selectedSpaceData = this.spacesWithPrimarySnapshots.find(space => space.space_long_id === this.selectedSpace)
                postBody.sid = selectedSpaceData.sid
            }
            return postBody
        },
        getPostBodyForDistribution() {
            const today = new Date()
            today.setDate(today.getDate() - 1)
            const yesterdayISO = today.toISOString()
            var postBody = {
                start_date: this.$data.dataDateRange[0],
                end_date: this.$data.dataDateRange[1],
                reporting_date: yesterdayISO
            }
            if (!this.$route.params.sid && this.selectedSpace !== 'All spaces') {
                const selectedSpaceData = this.spacesWithPrimarySnapshots.find(space => space.space_long_id === this.selectedSpace)
                postBody.sid = selectedSpaceData.sid
            }
            return postBody
        },
        fetchSummaryData: function() {
            const summaryApiURL = this.getSummaryApiUrl()
            const postBodySummary = this.getPostBodyForSummary()
            return this.$axios
                .post(summaryApiURL, postBodySummary)
                .then(response => {
                    this.$data.summaryData = response.data[0]
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Failed to fetch summary data.',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'error'
                    })
                })
        },
        fetchDistributionData: function() {
            const distributionApiURL = this.getSummaryDistributionApiUrl()
            const postBodyDistribution = this.getPostBodyForDistribution()
            return this.$axios
                .post(distributionApiURL, postBodyDistribution)
                .then(response => {
                    this.$data.distributionData = response.data
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Failed to fetch summary distribution data.',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'error'
                    })
                })
        },
        fetchStatistic: function() {
            const reportingApiURL = this.getReportApiUrl()
            const postBodyReporting = this.getPostBodyForReporting()
            this.$data.chartData = []
            this.$data.summaryData = []
            this.$data.distributionData = []
            this.$data.fetchingData = true
            this.$axios
                .post(reportingApiURL, postBodyReporting)
                .then(response => {
                    if (response.data && response.data.length) {
                        const returnedVariables = Object.keys(response.data[0])
                        this.$data.chartData = response.data

                        const tableHeaders = returnedVariables
                            .filter(variable => variable !== 'Reporting date')
                            .map(element => {
                                return { text: element, value: element }
                            })
                        const csvHeaders = {}
                        returnedVariables.forEach(element => {
                            if (element !== 'Reporting date') {
                                csvHeaders[element] = element
                            }
                        })
                        const defaultSelectedVariable = returnedVariables.filter(variable => this.$data.defaultVariables.includes(variable))[0]
                        this.$data.tableHeaders = tableHeaders
                        this.$data.csvHeaders = csvHeaders
                        this.$data.options = returnedVariables.filter(variable => this.$data.metricVariables.includes(variable)).map(variable => [variable])
                        this.$data.selectedVariable = [defaultSelectedVariable]
                    } else {
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: 'No data available for the selected period.',
                            snackBarTimeout: 10000,
                            snackBarIcon: 'info'
                        })
                    }
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Failed to fetch report.',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'error'
                    })
                })
                .finally(() => {
                    this.fetchSummaryData().finally(() => {
                        this.fetchDistributionData().finally(() => {
                            this.$data.fetchingData = false
                        })
                    })
                })
        },
        exportCSVFile: function(headers, data, fileTitle) {
            const items = Array.from(data)
            if (headers) {
                items.unshift(headers)
            }
            var jsonObject = JSON.stringify(items)
            var array = typeof jsonObject !== 'object' ? JSON.parse(jsonObject) : jsonObject
            var csv = ''

            for (var i = 0; i < array.length; i++) {
                var line = ''
                for (var index in array[i]) {
                    if (line !== '') line += ','
                    line += array[i][index]
                }
                csv += line + '\r\n'
            }
            var exportedFilenmae = this.$props.metric + '_statistics.csv'
            var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
            if (navigator.msSaveBlob) {
                // IE 10+
                navigator.msSaveBlob(blob, exportedFilenmae)
            } else {
                var link = document.createElement('a')
                if (link.download !== undefined) {
                    var url = URL.createObjectURL(blob)
                    link.setAttribute('href', url)
                    link.setAttribute('download', exportedFilenmae)
                    link.style.visibility = 'hidden'
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                }
            }
        },
        setDatePickerRange() {
            const todayDate = new Date()
            let oldestAllowedDate = new Date()
            oldestAllowedDate.setDate(oldestAllowedDate.getDate() - 60)
            oldestAllowedDate = oldestAllowedDate.toISOString().slice(0, 10)
            this.$data.datePickerAllowedRange = getDateRangeArray(oldestAllowedDate, todayDate)
        }
    },
    mounted() {
        // if (!this.$route.params.sid) {
        //    this.$data.selectedSpace = 'All spaces'
        // }
        // this.setInitialDoughnutNumberPerPage()
        // this.setDateRanges()
        // this.setDatePickerRange()
        // this.fetchStatistic()
    },
    watch: {
        screenSize: function(nextVal, preVal) {
            if (nextVal === 'large' || nextVal === 'medium') {
                this.$data.itemsPerPage = 2
            } else if (nextVal === 'small') {
                this.$data.itemsPerPage = 1
            } else {
                this.$data.itemsPerPage = 3
            }
        }
    }
}
</script>
