var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-form",
        {
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(
            "v-row",
            {
              staticClass: "mt-10",
              attrs: { "no-gutters": "", align: "center" }
            },
            [
              !_vm.$route.params.sid
                ? _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("v-select", {
                        staticClass: "hide-selector-extra-input mr-6",
                        attrs: {
                          required: "",
                          clearable: "",
                          items: _vm.spaceNames,
                          dense: "",
                          label: "Space"
                        },
                        model: {
                          value: _vm.selectedSpace,
                          callback: function($$v) {
                            _vm.selectedSpace = $$v
                          },
                          expression: "selectedSpace"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.metric === _vm.metrics.HPC_USAGE
                ? _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("v-select", {
                        staticClass: "hide-selector-extra-input mr-6",
                        attrs: {
                          required: "",
                          clearable: "",
                          items: _vm.userNames,
                          dense: "",
                          label: "User"
                        },
                        model: {
                          value: _vm.selectedUser,
                          callback: function($$v) {
                            _vm.selectedUser = $$v
                          },
                          expression: "selectedUser"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-col", { attrs: { cols: "3" } }, [
                _c(
                  "div",
                  { staticClass: "d-flex flex-column justify-end" },
                  [
                    _c("v-select", {
                      staticClass: "hide-selector-extra-input",
                      attrs: {
                        required: "",
                        items: _vm.dateRangeOptions,
                        dense: "",
                        label: "Date range"
                      },
                      on: {
                        change: function($event) {
                          return _vm.updateDateRange()
                        }
                      },
                      model: {
                        value: _vm.selectedDateRangeOption,
                        callback: function($$v) {
                          _vm.selectedDateRangeOption = $$v
                        },
                        expression: "selectedDateRangeOption"
                      }
                    }),
                    _vm.selectedDateRangeOption === "Custom range"
                      ? _c(
                          "v-menu",
                          {
                            attrs: {
                              "close-on-content-click": false,
                              transition: "scale-transition",
                              "offset-y": "",
                              "min-width": "290px"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                rules: [
                                                  _vm.rules.rangeRequired,
                                                  _vm.rules.maxRange,
                                                  _vm.rules.dateOrder
                                                ],
                                                dense: "",
                                                required: "",
                                                label: "Range",
                                                readonly: ""
                                              },
                                              model: {
                                                value: _vm.dateRangeText,
                                                callback: function($$v) {
                                                  _vm.dateRangeText = $$v
                                                },
                                                expression: "dateRangeText"
                                              }
                                            },
                                            "v-text-field",
                                            attrs,
                                            false
                                          ),
                                          on
                                        )
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3641286753
                            )
                          },
                          [
                            _c("v-date-picker", {
                              attrs: {
                                "allowed-dates": _vm.allowedDates,
                                range: ""
                              },
                              model: {
                                value: _vm.dataDateRange,
                                callback: function($$v) {
                                  _vm.dataDateRange = $$v
                                },
                                expression: "dataDateRange"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _c(
                "v-col",
                {
                  attrs: {
                    cols:
                      _vm.$route.params.sid &&
                      _vm.metric === _vm.metrics.HPC_USAGE
                        ? 6
                        : _vm.$route.params.sid
                        ? 9
                        : _vm.metric === _vm.metrics.HPC_USAGE
                        ? 3
                        : 6
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-start" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-5",
                          attrs: {
                            icon: "",
                            disabled: !_vm.valid,
                            loading: _vm.fetchingData,
                            text: "",
                            color: "primary"
                          },
                          on: { click: _vm.fetchStatistic }
                        },
                        [_c("v-icon", [_vm._v("refresh")])],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      !_vm.fetchingData &&
      _vm.chartData &&
      _vm.chartData.length &&
      _vm.completeSummaryData &&
      _vm.completeSummaryData.length
        ? _c(
            "div",
            [
              _c("div", { staticClass: "d-flex justify-center mt-5" }, [
                _c("span", { staticClass: "subtitle-2 primary--text" }, [
                  _vm._v(
                    "Reported data is as of " +
                      _vm._s(new Date().toISOString().slice(0, 10)) +
                      " 02:00 AM CEST"
                  )
                ])
              ]),
              _c("v-divider"),
              _c(
                "div",
                [
                  _vm.completeSummaryData && _vm.completeSummaryData.length
                    ? _c("v-data-iterator", {
                        attrs: {
                          page: _vm.page,
                          items: _vm.completeSummaryData,
                          "items-per-page": _vm.itemsPerPage,
                          "hide-default-footer": ""
                        },
                        on: {
                          "update:itemsPerPage": function($event) {
                            _vm.itemsPerPage = $event
                          },
                          "update:items-per-page": function($event) {
                            _vm.itemsPerPage = $event
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(props) {
                                return [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "py-5 px-5",
                                      attrs: {
                                        align: "center",
                                        justify: "space-around"
                                      }
                                    },
                                    _vm._l(props.items, function(data, index) {
                                      return _c(
                                        "v-col",
                                        {
                                          key: index,
                                          attrs: {
                                            cols:
                                              _vm.numberRowColumnsDoughnutCharts
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex flex-column align-center"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-center"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "subtitle-2 primary--text"
                                                    },
                                                    [_vm._v(_vm._s(data.title))]
                                                  ),
                                                  _c(
                                                    "v-menu",
                                                    {
                                                      attrs: {
                                                        "close-on-content-click": false,
                                                        "open-on-hover": "",
                                                        "nudge-width": 200,
                                                        "offset-x": ""
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "ml-1",
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                        icon: ""
                                                                      }
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "help_outline"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _c(
                                                        "v-card",
                                                        {
                                                          attrs: {
                                                            "max-width": "300",
                                                            flat: ""
                                                          }
                                                        },
                                                        [
                                                          _c("v-card-text", [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Please refer to our "
                                                              ),
                                                              _c(
                                                                "a",
                                                                {
                                                                  attrs: {
                                                                    href:
                                                                      "https://docs.nuvolos.cloud/settings-and-administration/monitoring-resource-usage",
                                                                    target:
                                                                      "_blank"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "documentation"
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                " for precise definitions and interpretation of the metrics provided in this report."
                                                              )
                                                            ])
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              false
                                                ? _c(
                                                    "v-avatar",
                                                    {
                                                      staticClass: "mt-3",
                                                      attrs: {
                                                        color: "secondary",
                                                        size: "100"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "white--text display-1"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(data.value)
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "max-width:300px;max-height:300px"
                                                },
                                                [
                                                  _c("doughnutChart", {
                                                    attrs: {
                                                      chartData:
                                                        data.distribution,
                                                      aggregateValue:
                                                        data.value,
                                                      chartIndex: "" + index
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    }),
                                    1
                                  )
                                ]
                              }
                            },
                            _vm.numberOfDoughnutChartPages > 1
                              ? {
                                  key: "footer",
                                  fn: function() {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-end align-center"
                                        },
                                        [
                                          _c("span", { staticClass: "mr-4" }, [
                                            _vm._v(
                                              " Page " +
                                                _vm._s(_vm.page) +
                                                " of " +
                                                _vm._s(
                                                  _vm.numberOfDoughnutChartPages
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mr-1",
                                              attrs: {
                                                disabled: _vm.page === 1,
                                                small: "",
                                                color: "info"
                                              },
                                              on: { click: _vm.formerPage }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-chevron-left")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "ml-1",
                                              attrs: {
                                                disabled:
                                                  _vm.page ===
                                                  _vm.numberOfDoughnutChartPages,
                                                small: "",
                                                color: "info"
                                              },
                                              on: { click: _vm.nextPage }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-chevron-right")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              : null
                          ],
                          null,
                          true
                        )
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm.chartData.length
                ? _c("v-divider", { staticClass: "mt-5" })
                : _vm._e(),
              _vm.chartData !== undefined &&
              _vm.chartData.length &&
              !_vm.fetchingData
                ? _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-space-between align-center mt-10 mb-5"
                    },
                    [
                      _c(
                        "v-btn-toggle",
                        {
                          model: {
                            value: _vm.tableChartToggle,
                            callback: function($$v) {
                              _vm.tableChartToggle = $$v
                            },
                            expression: "tableChartToggle"
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                small: "",
                                "max-height": "40",
                                "min-width": "50%"
                              }
                            },
                            [_vm._v("Chart")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                small: "",
                                "max-height": "40",
                                "min-width": "50%"
                              }
                            },
                            [_vm._v("Table")]
                          )
                        ],
                        1
                      ),
                      _vm.tableChartToggle === _vm.dataDisplayModes.CHART
                        ? _c("v-select", {
                            staticClass: "mr-3",
                            staticStyle: { "max-width": "450px" },
                            attrs: {
                              outlined: "",
                              items: _vm.options,
                              "hide-details": "",
                              dense: "",
                              label: "Quantity"
                            },
                            model: {
                              value: _vm.selectedVariable,
                              callback: function($$v) {
                                _vm.selectedVariable = $$v
                              },
                              expression: "selectedVariable"
                            }
                          })
                        : _c(
                            "v-btn",
                            {
                              staticClass: "primary--text",
                              attrs: { text: "" },
                              on: {
                                click: function($event) {
                                  return _vm.exportCSVFile(
                                    _vm.csvHeaders,
                                    _vm.tableChartData,
                                    "metrics"
                                  )
                                }
                              }
                            },
                            [
                              _c("v-icon", [_vm._v("mdi-download")]),
                              _vm._v("download as csv")
                            ],
                            1
                          )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.chartData !== undefined && _vm.chartData.length
                ? _c("div", [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.chartData &&
                              _vm.selectedVariable &&
                              _vm.tableChartToggle ===
                                _vm.dataDisplayModes.CHART,
                            expression:
                              "chartData && selectedVariable && tableChartToggle === dataDisplayModes.CHART"
                          }
                        ]
                      },
                      [
                        _vm.chartData
                          ? _c("barChart", {
                              attrs: {
                                maxNumberYTicks: _vm.distinctDataCount,
                                chartData: _vm.barChartData,
                                chartYaxisLabel:
                                  _vm.barchartYAxisLabelStrings[
                                    this.$data.selectedVariable[0]
                                  ]
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.tableChartToggle ===
                              _vm.dataDisplayModes.TABLE,
                            expression:
                              "tableChartToggle === dataDisplayModes.TABLE"
                          }
                        ]
                      },
                      [
                        _c("v-data-table", {
                          attrs: {
                            headers: _vm.tableHeaders,
                            items: _vm.tableChartData
                          }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm.fetchingData
        ? _c(
            "div",
            { staticClass: "d-flex flex-column" },
            [
              _c(
                "v-row",
                { attrs: { justify: "space-around", "no-gutters": "" } },
                _vm._l(3, function(n, i) {
                  return _c(
                    "v-col",
                    { key: i, attrs: { cols: "3" } },
                    [
                      _c("v-skeleton-loader", {
                        staticClass: "mx-auto",
                        attrs: { type: "list-item-two-line" }
                      })
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "v-row",
                { attrs: { justify: "space-around", "no-gutters": "" } },
                _vm._l(3, function(n, i) {
                  return _c(
                    "v-col",
                    { key: i, attrs: { cols: "3" } },
                    [
                      _c("v-skeleton-loader", {
                        staticClass: "py-10",
                        attrs: { type: "image" }
                      })
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "div",
                [
                  _c("v-skeleton-loader", {
                    staticClass: "mx-auto",
                    attrs: { type: "list-item-two-line" }
                  })
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("v-skeleton-loader", {
                    staticClass: "py-10",
                    attrs: { type: "image" }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm.fetchingData === false
        ? _c(
            "div",
            { staticClass: "mt-10" },
            [
              _c(
                "v-banner",
                { attrs: { "single-line": "" } },
                [
                  _c(
                    "v-avatar",
                    {
                      attrs: { slot: "icon", color: "white", size: "60" },
                      slot: "icon"
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: { "x-large": "", icon: "info", color: "info" }
                        },
                        [_vm._v(" info ")]
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "d-flex flex-column" }, [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("No data found")
                    ]),
                    _vm._v(" No data were found for the selected date range. ")
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }